<script setup lang="ts">
const { t } = useT();
const { open } = useNlcModals();

const props = withDefaults(
	defineProps<{
		variant?: "email" | "profile";
		coins?: number;
		entries?: number;
	}>(),
	{ variant: "email" }
);

const title = computed(() => (props.variant === "email" ? t("Verify Email") : t("Complete profile")));
const icon = computed(() => `/nuxt-img/rewards/${props.variant === "email" ? "email" : "profile"}.png`);

const handleClick = () => {
	if (props.variant === "email") {
		open("LazyOModalEmailConfirm");
		return;
	}

	open("LazyOModalEasyProfileCompleted");
};
</script>

<template>
	<div @click="handleClick">
		<div class="icon">
			<NuxtImg :src="icon" width="72" height="72" loading="lazy" format="avif" quality="90" alt="rewards item" />
		</div>

		<div class="content">
			<AText class="title color-neutral-100" :modifiers="['uppercase', 'bold']">{{ title }}</AText>
			<div class="row">
				<AText class="prizes color-neutral-100" variant="toledo" :modifiers="['bold']">
					<MPrizeFund v-if="coins" icon="20/coins" variant="coins" :iconSize="16">
						{{ numberFormat(Number(coins)) }}
					</MPrizeFund>
					<template v-if="coins && entries"> + </template>
					<MPrizeFund v-if="entries" icon="20/entries" variant="entries" :iconSize="16">
						{{ numberFormat(Number(entries)) }}
					</MPrizeFund>
				</AText>
			</div>
		</div>
		<AButton variant="primary" size="s">
			<AText variant="topeka" :modifiers="['uppercase']">{{ t("claim") }}</AText>
		</AButton>
	</div>
</template>

<style lang="scss" scoped>
.content {
	display: flex;
	flex-direction: column;
	gap: 6px;
}
.prizes {
	display: flex;
	align-items: center;
	gap: 6px;

	.prize {
		gap: 4px;
	}
}

.icon {
	width: 48px;
	height: 48px;
	display: flex;
	align-items: center;

	img {
		width: 48px;
		height: 48px;
	}
}
</style>
